import { debounce } from 'lodash';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { InView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import { Store } from 'store/Base';

// Debounce in ms before element will start to load, can be tweaked for different experience
const DEBOUNCE_BEFORE_LOAD = 100;

// Virtualized list upper limit
const UPPER_ELEMENTS_LIMIT = 1000;

const RENDER_INVIEW_ADDITIOANL_ROWS = 10;

@observer
class VirtualizedListElement extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(Store).isRequired,
        index: PropTypes.number.isRequired,
        inViewRange: PropTypes.bool.isRequired,
        ElementTemplate: PropTypes.func.isRequired,
        data: PropTypes.object,
    };


    @observable dataToRender = null;
    @observable inView = null;

    onInViewChange = debounce((inView) => {
        const { data } = this.props;
        this.inView = inView;

        if (data?.id == null && inView) {
            this.fetchData();
        }

    }, DEBOUNCE_BEFORE_LOAD);

    async fetchData() {
        const { store, data } = this.props;
        await store.loadVirtualModel(data.vId);
    }

    render() {
        const { ElementTemplate, index, data, inViewRange } = this.props;

        return (
            <InView as="div" onChange={this.onInViewChange} style={{}}>
                <ElementTemplate index={index} data={data} inView={inViewRange} />
            </InView>
        )
    }

}

/**
 * Virtualized List makes it possible to present large stores in efficient matters
 * It shows preloaded elements from the store (for example limit 25) and uses totalRecords
 * to render placeholders for the rest of the items.
 *
 * @store: BaseStore instance
 * @ElementTemplate: function that receives data param containing element to render
 *                   If data is empty it means that placeholder should be rendered
 *                   Custom props can be injected using lambda as shown in example with store
 *
 * Example:
 *
 * function TripRowCustom(props, store) {
 *     const { index, data } = props;
 *     if (data == null) {
 *         return <div style={{ height: 100 }} ><span>Placeholder rendering here</span></div>
 *     }
 *     return <div style={{ height: 100 }} ><h1>Hello, id {data.id} at index {index}</h1></div >;
 * }
 * <VirtualizedList store={store.tripStore} ElementTemplate={(elementData) => TripRowCustom(elementData, store)} />
 *
 * Possible improvements:
 *  - Loading elements in batches of size X (for example 10 )
*/
@observer
class VirtualizedList extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(Store).isRequired,
        ElementTemplate: PropTypes.func
    };

    localStore;
    @observable totalLines;
    inViewIndices = [0, 50];

    @observable inViewMin = 0;
    @observable inViewMax = 50;

    getData(index) {
        const { store } = this.props;
        let data;

        if (store.models.length > index) {
            data = store.models[index];
        }

        return data
    }

    getLength() {
        return Math.min(this.totalLines, UPPER_ELEMENTS_LIMIT);
    }

    reachedMax() {
        return this.getLength() === UPPER_ELEMENTS_LIMIT;
    }

    onInViewChange(inView, index) {
        if (inView) {
            this.inViewIndices.push(index);
        } else {
            this.inViewIndices = this.inViewIndices.filter(v => v !== index);
        }

        this.calcInViewMinMax();
    }

    calcInViewMinMax = debounce(() => {
        this.inViewMin = Math.min(...this.inViewIndices);
        this.inViewMax = Math.max(...this.inViewIndices);
    }, 100)

    isInViewRange(index) {
        return index > this.inViewMin - RENDER_INVIEW_ADDITIOANL_ROWS && index < this.inViewMax + RENDER_INVIEW_ADDITIOANL_ROWS;
    }

    render() {
        const { ElementTemplate, store } = this.props;
        let dataNotLoaded = 0;

        if (!store) {
            return null;
        }

        return <>
            {/* TotalLines: {this.totalLines} */}
            {/* {Array.from({ length: this.getLength() }).map((_v, index) => { */}
            {store.models?.map((data, index) => {
                if (data.id == null) {
                    dataNotLoaded += 1;
                }
                if (dataNotLoaded > RENDER_INVIEW_ADDITIOANL_ROWS) {
                    return null;
                }
                return (
                    <InView as="div"
                        onChange={(inView) => this.onInViewChange(inView, index)}
                        key={`idx${index}-id${data.id}-vid${data.vId}`}>
                        <VirtualizedListElement
                            index={index}
                            data={data}
                            inViewRange={this.isInViewRange(index)}
                            store={store}
                            ElementTemplate={ElementTemplate} />
                    </InView>
                )
            }
            )}
            {this.reachedMax() && <span>You reached max number of elements in the list. Adjust your filter.</span>}
        </>
    }
}

export default VirtualizedList
