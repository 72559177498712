import { Model, Store, Casts } from 'store/Base';
import { observable, computed } from 'mobx';
import SalesPlanType from './enums/SalesPlanType';
import { Activity } from './Activity';
import { District } from './District';
import { TripStore } from './Trip';
import { TRAILER } from './enums/AssetType';
import ActivityStatus from './enums/ActivityStatus';
import { UserFlagsStore } from './UserFlags';
import { VirtualizedStore } from './VirtualizedStore';
export class SalesPlan extends Model {
    static backendResourceName = 'sales_plan';

    @observable id = null;
    @observable type = SalesPlanType.TYPE_NEW;
    @observable planDate = null;
    @observable location = t('capacity.fields.unknown.label');
    @observable remarks = '';

    casts() {
        return {
            planDate: Casts.date,
        };
    }

    relations() {
        return {
            trips: TripStore,
            activity: Activity,
            district: District,
            userFlags: UserFlagsStore
        }
    }

    @computed get trailer() {
        return this.activity?.m2mTrips.models.find((model) => model.trip.type === TRAILER)?.trip.trailer
    }

    @computed get truck() {
        return this.activity?.m2mTrips.models.find((model) => model.trip.type === TRAILER)?.trip.trailer.attachedTruck
    }

    @computed get getColor() {
        return this.trailer?.getColor;
    }


    @computed get identificator() {
        return this.trailer?.fleetNumber
    }

    @computed get adr() {
        return this.trailer?.attachedTruck?.adr
    }

    @computed get driver() {
        return this.trailer?.attachedTruck?.driver
    }


    @computed get currentLocationString() {
        if (this.activity != null && this.activity.id != null) {
            return this.activity.location.toString();
        } else {
            return this.location;
        }
    }

    @computed get currentLocationInformation() {
        return null;
    }

    getLastTrackedChanges() {
        if (this.activity != null && this.activity.id != null) {
            return this.activity.getLastTrackedChanges();
        }

        return null;
    }

    removeSalesPlan = async (deleteOldSalesPlans) => {
        return this.api.post(`${this.url}remove_sales_plan/`, { delete_old_plans: deleteOldSalesPlans });
    }

    hasWarnings() {
        const trackedChanges = this.getLastTrackedChanges();
        const hasTrackedChanges = trackedChanges && trackedChanges.length > 0;
        const isNotExecuting = this.activity?.isNotExecuting;
        const isLastOrderActivity = this.activity?.isLastOrderActivity ?? true;
        const isCanceled = this.activity?.status === ActivityStatus.CANCELED;

        return hasTrackedChanges || isNotExecuting || !isLastOrderActivity || isCanceled
    }
}

export class SalesPlanStore extends Store {
    Model = SalesPlan;
    static backendResourceName = 'sales_plan';
}

export class VirtualizedSalesPlanStore extends VirtualizedStore {
    Model = SalesPlan;
    static backendResourceName = 'sales_plan';
}
