import { Model, Store, Casts } from 'store/Base';
import { observable, computed } from 'mobx';
import { TruckingCompany } from './TruckingCompany';
import { CURRENCY_EURO, CURRENCY_POUND, Order } from './Order';
import OrderSubcontractStatus from './enums/OrderSubcontractStatus';
import { formatMoneyEuro, formatMoneyPound, formatPercentage } from 'helpers';
import { Email } from './Email';
import { TripStore } from './Trip';
import { TYPE_UPDATE } from './enums/ContactTypes';
import { ContactStore } from './Contact';

export class OrderSubcontract extends Model {
    static backendResourceName = 'order_subcontract';

    @observable id = null;
    @observable status = OrderSubcontractStatus.DRAFT;

    @observable truckLicensePlate = '';
    @observable trailerLicensePlate = '';
    @observable driverName = '';

    @observable paymentTerms = 60;

    // Deprecated salesprice is used as purchase price in system
    @observable purchasePrice = 0;

    // Currently used as a purchase price, so name should be changed
    @observable salesPrice = '';

    @observable packagingAmount = '';
    @observable packagingType = null;
    @observable weight = '';

    // [TODO] change name to adr
    @observable unNumber = false;

    @observable goodsDescription = '';
    @observable remarks = '';

    _contactStore = new ContactStore();

    relations() {
        return {
            transportCompany: TruckingCompany,
            order: Order,
            trips: TripStore,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    @computed get marginBrutto() {
        return this.order.salesPrice - this.salesPrice;
    }

    @computed get marginPercent() {
        if (this.order != null && this.order.salesPrice < 1) {
            return 0;
        }
        return (this.marginBrutto / this.order?.salesPrice) * 100;
    }

    @computed get formattedMarginPercent() {
        return formatPercentage(this.marginPercent);
    }

    @computed get formattedSalesPrice() {
        if (this.currency === CURRENCY_EURO) {
            return formatMoneyEuro(this.salesPrice);
        } else if (this.currency === CURRENCY_POUND) {
            return formatMoneyPound(this.salesPrice);
        }

        throw new Error(`Unknown currency: ${this.currency}`);
    }

    @computed get pdfPreviewUrl() {
        return `/api${this.url}pdf/`;
    }

    @computed get pdfDownloadUrl() {
        return `${this.pdfPreviewUrl}?download=true`;
    }

    cancel() {
        this.status = OrderSubcontractStatus.CANCELED;
        return this.save();
    }

    getContactStore() {
        this._contactStore.params = {
            '.type': TYPE_UPDATE,
            '.email_address:not': '',
            '.trucking_company.id': this.transportCompany?.id,
        }
        return this._contactStore
    }

    getEmail() {
        return new Email().generate(
            `Freight Forwarding Transport Order ${this.order.orderNumber}`,
            `In the attachment you can find your Transport Order ${this.order.orderNumber}. \n` +
            'For questions regarding operations, please contact rick@dalessi.nl ; jorrit@dalessi.nl \n' +
            'Please send your invoice to adminstratie@dalessi.nl \n' +
            'If you have any questions, do not hesitate to contact us. \n\n' +
            'Kind Regards, \n' +
            'The Dalessi Team \n',
            []
        )
    }

    getCustomerId() {
        return this.order?.customer?.id ?? -1;
    }

    loadFromOrder(order) {
        this.order.id = order.id;
        this.order.orderNumber = order.orderNumber;
        this.order.salesPrice = order.salesPrice;
        this.packagingAmount = order.packagingAmount;
        this.packagingType = order.packagingType;
        this.weight = order.weight;
        this.unNumber = order.unNumber;
        this.goodsDescription = order.goodsDescription;
        this.remarks = order.remarks;

        this.order.trips = order.trips;
    }

    loadFromOrderBulk(order, transportCompany, goodsDescription) {
        this.loadFromOrder(order);
        this.order.parse(order.toJS());
        this.transportCompany = transportCompany;
        if (this.goodsDescription != null) {
            this.goodsDescription = goodsDescription;
        }
    }

    @computed get statusColorOverview() {
        switch (this.status) {
            case OrderSubcontractStatus.DRAFT:
                return 'var(--orange-300)'
            case OrderSubcontractStatus.SENT:
                return 'var(--green-300)'
            case OrderSubcontractStatus.CANCELED:
                return 'var(--red-300)';
            default:
                return 'var(--blue-300)';
        }
    }


    @computed get statusColorSemantic() {
        switch (this.status) {
            case OrderSubcontractStatus.DRAFT:
                return 'orange'
            case OrderSubcontractStatus.SENT:
                return 'green'
            case OrderSubcontractStatus.CANCELED:
                return 'red';
            default:
                return 'blue';
        }
    }

    @computed get owned() {
        if (this.transportCompany == null || this.transportCompany.id == null) {
            return false;
        }

        return !this.transportCompany?.subcontractor;
    }

    @computed get attachmentName() {
        // DRAFT subcontract % s.pdf' % self.id
        return `DRAFT subcontract ${this.id}.pdf`;
    }

    @computed get identificator() {
        return this.transportCompany.name;
    }

    @computed get getColor() {
        if (this.id == null) {
            return 'var(--orange-100)'
        }
        return null;
    }

    @computed get colorBorder() {
        return this.transportCompany?.colorCompany;
    }

    @computed get ownerName() {
        return this.transportCompany?.name;
    }

    @computed get currentLocationString() {
        return '';
    }

    @computed get currentLocationInformation() {
        return null;
    }

    @computed get currency() {
        return this.order?.currency ?? CURRENCY_EURO;
    }

    getOrderDocuments() {
        return this.order?.documents ?? [];
    }

    getAttachmentInfo() {
        const attachment = {
            id: `E${this.id}`,
            url: this.pdfDownloadUrl,
            previewUrl: this.pdfPreviewUrl,
            name: this.attachmentName,
            optional: false,
            type: 'order',
        }

        const otherDocuments = this.getOrderDocuments()?.map(doc => {
            return {
                id: `D${doc.id}`,
                url: doc.downloadUrl,
                previewUrl: doc.previewUrl,
                name: doc.name,
                optional: true,
                type: 'order documents',
            }
        });

        return [attachment, ...otherDocuments];
    }
}

export class OrderSubcontractStore extends Store {
    Model = OrderSubcontract;
    static backendResourceName = 'order_subcontract';
}
