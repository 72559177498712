import { Model, Store, Casts, OrderedStore } from 'store/Base';
import { action, computed, observable } from 'mobx';
import { Order } from './Order';
import { TripActivitiesStore } from './TripActivities';
import { Trailer } from './Trailer';
import { Truck } from './Truck';
import TripStatus from './enums/TripStatus';
import { SalesPlan } from './SalesPlan';
import { showErrorNotification, showErrorNotifications, showSaveNotification } from 'helpers/notification';
import { OrderSubcontract } from './OrderSubcontract';
import TripType from './enums/TripType';
import { VirtualizedStore } from './VirtualizedStore';
import ActivityStatus from './enums/ActivityStatus';

export class Trip extends Model {
    static backendResourceName = 'trip';


    @observable id = null;
    @observable status = TripStatus.STATUS_NEW;
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable ordering = 0;
    @observable type = '';
    @observable plannedKm = 0;

    @computed get activities() {
        return this.m2mActivities.map(rel => rel.activity)
    }

    getTrailerIdentificator() {
        if (this.trailer != null) {
            return this.trailer.identificator;
        }
        return '';
    }

    @computed get identificator() {
        if (this.trailer != null) {
            return this.trailer.identificator;
        } else if (this.truck != null) {
            return this.truck.identificator;
        }
        return '';
    }

    getActivitiesSorted() {
        return this.m2mActivities?.models?.sort((a, b) => a.ordering - b.ordering);
    }

    @computed get calculatedStatus() {
        const isFinalized = this.m2mActivities.filter(
            tripActivity => tripActivity.activity.status !== ActivityStatus.FINALIZED
        ).length === 0;
        if (isFinalized) {
            return TripStatus.STATUS_FINALIZED;
        }

        const isFinished = this.m2mActivities.filter(
            tripActivity => ![ActivityStatus.FINISHED, ActivityStatus.FINALIZED].includes(tripActivity.activity.status)
        ).length === 0;
        if (isFinished) {
            return TripStatus.STATUS_FINISHED;
        }

        const isExecuting = this.m2mActivities.filter(tripActivity =>
            [ActivityStatus.ETA, ActivityStatus.ARRIVED, ActivityStatus.WAITING, ActivityStatus.STARTED].includes(tripActivity.activity.status)
        ).length > 0;
        if (isExecuting) {
            return TripStatus.STATUS_EXECUTING;
        }

        return null;
    }

    @computed get getFirstActivity() {
        if (this.m2mActivities.length > 0) {
            return this.m2mActivities.at(0).activity;
        }

        return null;
    }

    getLastActivity() {
        if (this.order?.lastActivity?.location?.id != null) {
            return this.order?.lastActivity;
        }
        const activities = this.getActivitiesSorted()

        if (activities?.length > 0) {
            return this.getActivitiesSorted()[activities.length - 1]?.activity;
        }

        return null;
    }

    @action
    async dropTrip(assetType, sourceAssetId, targetAssetId, targetTripId, targetAssetType) {
        if (this.id > 0) {
            return this.api.put(this.url + 'drop-trip/', { assetType, sourceAssetId, targetAssetId, targetTripId, targetAssetType })
                .then((response) => {
                    return { success: true, data: response.data };
                })
                .catch((err) => {
                    showErrorNotifications(err.response.data.errors);
                    return { success: false, err };
                });
        }

        return false;
    }

    async splitAfterActivity(activity) {
        return this.api.post(
            this.url + 'split-after-activity/',
            { activity: activity.id }
        ).then(showSaveNotification).catch((err) => showErrorNotification(err.response.data.errors))
    }

    @computed
    get canBePlanned() {
        if (this.type === TripType.TYPE_ORDER_SUBCONTRACT) {
            return false;
        }

        return this.status === TripStatus.STATUS_NEW || this.status === TripStatus.STATUS_CANCELED ||
            this.status === TripStatus.STATUS_PLANNED;
    }

    @computed
    get isCompleted() {
        return this.status === TripStatus.STATUS_FINISHED || this.status === TripStatus.STATUS_FINALIZED || this.status === TripStatus.CANCELED;
    }

    @computed
    get shouldShowTrailer() {
        return this.type === 'truck' && this.hasTrailerAssigned;
    }

    @computed
    get hasTrailerAssigned() {
        return this.trailer?.id != null;
    }

    @computed
    get trailerAssigned() {
        return this.trailer;
    }

    @computed
    get statusColorOverview() {
        switch (this.status) {
            case TripStatus.STATUS_NEW:
                return 'var(--orange-200)'
            case TripStatus.STATUS_PLANNED:
                return 'var(--blue-300)'
            case TripStatus.STATUS_EXECUTING:
                return 'var(--orange-300)'
            case TripStatus.STATUS_FINALIZED:
                return 'var(--green-300)'
            case TripStatus.STATUS_FINISHED:
                return 'var(--purple-200)'
            case TripStatus.STATUS_CANCELED:
                return 'var(--red-300)'
            default:
                return 'var(--gray-100)';
        }
    }

    @computed
    get statusColorSemantic() {
        switch (this.status) {
            case TripStatus.STATUS_NEW:
                return 'orange'
            case TripStatus.STATUS_PLANNED:
                return 'blue'
            case TripStatus.STATUS_EXECUTING:
                return 'orange'
            case TripStatus.STATUS_FINISHED:
            case TripStatus.STATUS_FINALIZED:
                return 'green'
            case TripStatus.STATUS_CANCELED:
                return 'red'
            default:
                return 'grey';
        }
    }

    relations() {
        return {
            order: Order,
            parent: Trip,
            trailer: Trailer,
            salesPlan: SalesPlan,
            orderSubcontract: OrderSubcontract,
            truck: Truck,
            children: UnorderedTripStore,
            m2mActivities: TripActivitiesStore,
        };
    }

    triggerCalculateKm() {
        return this.api.post(`${this.url}trigger_calculate_km/`);
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }
}

export class UnorderedTripStore extends Store {
    Model = Trip;
    static backendResourceName = 'trip';
}

export class UnorderedVirtualizedTripStore extends VirtualizedStore {
    Model = Trip;
    static backendResourceName = 'trip';

    getOpenTripCounts(data) {
        return this.wrapPendingRequestCount(
            this.api.get(this.url() + 'open_trip_counts/', data)
        );
    }
}

export const TripStore = OrderedStore(UnorderedTripStore, 'ordering');
export const VirtualizedTripStore = OrderedStore(UnorderedVirtualizedTripStore, 'ordering');