import { Model, Store, Casts } from 'store/Base';
import { observable, computed } from 'mobx';
import SurchargeStatus from '../enums/SurchargeStatus';
import { Surcharge } from './Surcharge';
import { CURRENCY_EURO } from '../enums/InvoiceCurrency';
import { now } from 'helpers';

export class SurchargeAmount extends Model {
    static backendResourceName = 'surcharge_amount';

    @observable id = null;
    @observable amount = 0;
    @observable percentage = 0;
    @observable status = SurchargeStatus.DRAFT;

    @observable currency = CURRENCY_EURO;


    @observable validFrom = now().plus({ day: 1 }).startOf('day');
    @observable validTo = null;
    @observable updatedAt = null;
    @observable createdAt = null;

    @observable expiresNote = '';

    @computed
    get canBeEdited() {
        return this.status === SurchargeStatus.FUTURE || this.status === SurchargeStatus.DRAFT;
    }

    @computed
    get canBeDeleted() {
        return this.status === SurchargeStatus.FUTURE || this.status === SurchargeStatus.DRAFT;
    }

    @computed
    get canBeDeactivated() {
        return this.status === SurchargeStatus.CURRENT && !this.endDate;
    }

    relations(){
        return {
            surcharge: Surcharge,
        }
    }

    casts() {
        return {
            validFrom:  Casts.date,
            validTo: Casts.date,
            updatedAt: Casts.datetime,
            createdAt: Casts.datetime,
        }
    }
}

export class SurchargeAmountStore extends Store {
    Model = SurchargeAmount;
    static backendResourceName = 'surcharge_amount';
}
