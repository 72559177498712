import { Model, Store, Casts } from 'store/Base';
import { computed, observable } from 'mobx';
import { Schedule } from './Schedule';
import { Activity } from './Activity';
import { getStatusColor } from './enums/StatusColors';
import BOOKING_STATUS from './enums/BookingStatus';
import PurchaseInvoiceStatus from './enums/PurchaseInvoiceStatus';
import BookingIntegrationStatus from './enums/BookingIntegrationStatus';
import { DATETIME_FORMAT, DATETIME_FORMAT_SHORT, formatMoneyEuro } from 'helpers';
import TripType from './enums/TripType';
import { CargoLineStore } from './CargoLine';
import { PurchaseInvoiceTerminalTransporterBookingLineStore } from './PurchaseInvoiceTerminalTransporterBookingLine';

export class Booking extends Model {
    static backendResourceName = 'booking';

    @observable id = null;
    @observable status = BOOKING_STATUS.DRAFT;
    @observable integrationStatus = BookingIntegrationStatus.NONE;
    @observable bookingReference = '';
    @observable licensePlate = '';
    @observable mrnNumber = '';

    @observable unNumber = null;
    @observable adr = false;
    @observable classNumber = null;
    @observable packageGroup = null;
    @observable lq = false;

    @observable bookingDate = null;
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable purchasePrice = '';
    @observable purchaseInvoiceStatus = BOOKING_STATUS.DRAFT;

    // deprecated
    // @observable purchaseInvoiceAmount = '';

    @observable cargoDescription = '';
    @observable additionalInformation = '';

    @observable trailerFleetNumber = '';
    @observable packagingAmount = '';
    @observable packagingType = '';
    @observable weight;
    @observable ucrNumber = '';
    @observable communityCode = '';
    @observable shippingReference = '';
    @observable pinCode = '';
    @observable trailerEmpty = false;
    @observable hasTasks = false;

    @observable gmrNumber = '';

    relations() {
        return {
            fromActivity: Activity,
            toActivity: Activity,
            schedule: Schedule,
            cargoLines: CargoLineStore,
            purchaseInvoiceLines: PurchaseInvoiceTerminalTransporterBookingLineStore,
        };
    }

    casts() {
        return {
            bookingDate: Casts.luxonDate,
            createdAt: Casts.luxonDate,
            updatedAt: Casts.luxonDate,
        };
    }

    @computed get tagLabel() {
        return `B${this.bookingReference}`;
    }

    @computed get editUrl() {
        return `/admin/booking/${this.id}/edit`;
    }

    @computed get statusCombined() {
        if (this.integrationStatus === BookingIntegrationStatus.NONE) {
            return this.status;
        }

        return `${this.status} - ${this.integrationStatus}`;
    }
    @computed get statusColor() {
        return getStatusColor(this.status);
    }

    @computed get formattedPurchasePrice() {
        return formatMoneyEuro(this.purchasePrice);
    }

    getTrailerTrip() {
        return this.fromActivity.m2mTrips.find(tripActivity => tripActivity.trip.type === TripType.TYPE_TRAILER)?.trip;
    }

    @computed get invoiceStatusColorPlanning() {
        switch (this.purchaseInvoiceStatus) {
            case PurchaseInvoiceStatus.NOT_PAID:
                return 'var(--red-50)'
            case PurchaseInvoiceStatus.IN_PROGRESS:
                return 'var(--orange-50)'
            case PurchaseInvoiceStatus.TRANSFERRED:
                return 'var(--blue-50)'
            case PurchaseInvoiceStatus.PAID:
                return 'var(--green-50)'
            default:
                return 'var(--gray-100)';
        }
    }

    @computed get invoiceStatusColorOverview() {
        switch (this.purchaseInvoiceStatus) {
            case PurchaseInvoiceStatus.NOT_PAID:
                return 'var(--red-300)';
            case PurchaseInvoiceStatus.IN_PROGRESS:
                return 'var(--orange-300)'
            case PurchaseInvoiceStatus.TRANSFERRED:
                return 'var(--blue-300)'
            case PurchaseInvoiceStatus.PAID:
                return 'var(--green-300)'

            default:
                return 'var(--gray-300)';
        }
    }

    @computed get invoiceStatusColorSemantic() {
        switch (this.purchaseInvoiceStatus) {
            case PurchaseInvoiceStatus.NOT_PAID:
                return 'red';
            case PurchaseInvoiceStatus.IN_PROGRESS:
                return 'orange'
            case PurchaseInvoiceStatus.TRANSFERRED:
                return 'blue'
            case PurchaseInvoiceStatus.PAID:
                return 'green'

            default:
                return 'grey';
        }
    }
    @computed get actualAndBookedArrivalDiffers() {
        if (this.bookingDate && this.schedule?.arrival && this.toActivity?.customs?.ataBerth) {
            const bookedArrival = this.schedule.toBookingArrivalDate(this.bookingDate)
            const actualArrival = this.toActivity?.customs?.ataBerth
            const differenceInMinutes = bookedArrival.diff(actualArrival, 'minutes').toObject().minutes
            return Math.abs(differenceInMinutes) > 180;
        }

        return false;
    }

    stringFromScheduleDepartureDate(defaultValue) {
        if (this.bookingDate && this.schedule?.departure) {
            return this.schedule.toBookingDepartureDate(this.bookingDate).toFormat(DATETIME_FORMAT);
        }

        return defaultValue;
    }

    stringActualDepartureDate(defaultValue) {
        if (this.toActivity?.customs?.atdBerth) {
            return this.toActivity?.customs?.atdBerth.toFormat(DATETIME_FORMAT);
        }

        return defaultValue;
    }

    stringFromScheduleArrivalDate(defaultValue) {
        if (this.bookingDate && this.schedule?.arrival) {
            return this.schedule.toBookingArrivalDate(this.bookingDate).toFormat(DATETIME_FORMAT);
        }

        return defaultValue;
    }

    stringActualArrivalDate(defaultValue) {
        if (this.toActivity?.customs?.ataBerth) {
            return this.toActivity?.customs?.ataBerth.toFormat(DATETIME_FORMAT);
        }

        return defaultValue;
    }

    stringActualArrivalDateShort(defaultValue) {
        if (this.toActivity?.customs?.ataBerth) {
            return this.toActivity?.customs?.ataBerth.toFormat(DATETIME_FORMAT_SHORT);
        }

        return defaultValue;
    }
}

export class BookingStore extends Store {
    Model = Booking;
    static backendResourceName = 'booking';
}
