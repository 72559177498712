import { getCurrentUser } from 'helpers/currentUser';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { subscribe } from 'store/Base';
import { MESSAGES, TASKS } from 'store/enums/SidebarMenuItems';
import TaskStatus from 'store/enums/TaskStatus';
import { TaskStore } from 'store/Task';
import styled from 'styled-components';
import CyLink from './Sidebar/CyLink';
import SidebarContentTasks from './Sidebar/SidebarContentTasks';
import SidebarContentMessages from './Sidebar/SidebarContentMessage';
import { MessageStore } from 'store/Message';

const Sidebar = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 auto;
    width: 50px;
    z-index: 100;
    border-left: 1px solid var(--gray-200);
    position: relative;
`;


const SidebarContent = styled.div`
    ${({ open }) => open ? `
        right: 50px;
        box-shadow: -4px 4px 8px 0px rgba(158,158,158,0.75);
    ` : `
        right: -400px;
        box-shadow: 0 0 5px 3px transparent;
    `}

    width: 360px;
    background-color: #FFF;
    z-index: 50;
    position: absolute;
    height: 95vh;
    overflow: hidden;

    transition: box-shadow 300ms ease, right 300ms ease;
`;

const AbsoluteWrapper = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 100;
    background-color: var(--gray-50);
`

const CustomMenu = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
`;

const CustomMenuItem = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 50px;
    color: green;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ${({ active }) => active ? `
        background-color: var(--cy-gray) !important;
        color: var(--gray-50) !important;
    ` : `
        background-color: transparent;
        color: var(--gray-500);
    `}

    :hover {
        background-color: var(--gray-300);
        color: var(--gray-500);
    }

    transition: color 300ms ease, background-color 300ms ease;

`;

const CustomBadge = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 4px;
    top: 4px;
    background-color: var(--red-600);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 11px;
    color: var(--white);
    font-family: Lato;
    font-weight: bold;
    user-select: none;
`;

const MAX_MESSAGE_COUNT_SHOWN = 100;

@observer
export default class AppSidebar extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
    };

    @observable activeMenuItem = null;
    @observable activeTabContent = null;

    @observable taskCount = 0;
    @observable taskStore = new TaskStore({ params: { '.status:not': TaskStatus.DONE } });

    @observable messageCount = 0;
    @observable messageStore = new MessageStore({
        params: {
            '.source': 'driver',
            '.read': 'false'
        },
        limit: 1 // I only want to get the count and limit 0 doesn't work
    });

    _subscriptions = [];

    async componentDidMount() {
        this.loadData();
        this.subscribe();
    }

    componentWillUnmount() {
        this.unsubscribeAll();
    }

    loadData() {
        const { store } = this.props;
        const currentUser = getCurrentUser();

        // Only load your tasks
        if (currentUser?.id != null) {
            this.taskStore.params['.assigned.id'] = getCurrentUser()?.id;

            // Check if permission is granted
            if (store.currentUser.hasPermission('task.view_task:all')) {
                this.taskStore.fetch().then(() => this.taskCount = this.taskStore.__state.totalRecords);
            }
        }

        // Load unread message count
        this.messageStore.fetch().then(() => this.messageCount = this.messageStore.__state.totalRecords);

    }

    subscribe() {
        this._subscriptions.push(subscribe({
            target: 'task-unfinished-count',
            'user-id': getCurrentUser()?.id,
        }, (msg) => {
            this.taskCount = msg.data;
        }));

        this._subscriptions.push(subscribe({
            target: 'message-unread-count',
        }, action((msg) => {
            this.messageCount = msg.data?.unread;
        })));
    }

    unsubscribeAll() {
        this._subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        })
    }

    menuItemClick = (e, name) => {
        e.stopPropagation();

        if (this.activeMenuItem === name) {
            this.activeMenuItem = null;
        } else {
            this.activeMenuItem = name;
        }

        this._switchActiveTab();
    }

    _renderMenu() {
        return (
            <CustomMenu>
                <CustomMenuItem active={this.activeMenuItem === TASKS}
                    onClick={(e) => this.menuItemClick(e, TASKS)}
                >
                    <Icon name={TASKS} size="large">
                        {this.taskCount > 0 ? <CustomBadge data-test-open-tasks>{this.taskCount}</CustomBadge> : null}
                    </Icon>
                </CustomMenuItem>
                <CustomMenuItem active={this.activeMenuItem === MESSAGES}
                    onClick={(e) => this.menuItemClick(e, MESSAGES)}
                    data-test-sidebar-messages
                >
                    <Icon name='comments' size="large">
                        {this.messageCount > 0 ? <CustomBadge data-test-unread-messages>{this.messageCount < MAX_MESSAGE_COUNT_SHOWN ? this.messageCount : '!!'}</CustomBadge> : null}
                    </Icon>
                </CustomMenuItem>
                <CustomMenuItem >
                    <Icon name='home' size="large" onClick={() => window.open('http://chat.dalessi.incenova.com', '_blank').focus() }>
                    </Icon>
                </CustomMenuItem>
            </CustomMenu>
        )
    }

    closeTab = () => {
        // First animate closing and after timeout remove the content,
        // os it is refreshed next time opened
        this.activeMenuItem = null;

        setTimeout(() => {
            this.activeTabContent = null;
        }, 300)
    }

    _switchActiveTab() {
        switch (this.activeMenuItem) {
            case TASKS:
                this.activeTabContent = <SidebarContentTasks hideSidebarContent={() => this.closeTab()}></SidebarContentTasks>;
                break;
            case MESSAGES:
                this.activeTabContent = <SidebarContentMessages hideSidebarContent={() => this.closeTab()}></SidebarContentMessages>;
                break;
            default:
                this.closeTab();
        }
    }

    render() {
        return (
            <Sidebar onClick={this.closeTab}>
                <SidebarContent open={this.activeMenuItem !== null} onClick={e => e.stopPropagation()}>
                    {this.activeTabContent}
                </SidebarContent>
                <AbsoluteWrapper>
                    {this._renderMenu()}
                    <CyLink />
                </AbsoluteWrapper>
            </Sidebar>
        );
    }
}
