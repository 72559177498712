import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Customer } from './Customer';
import { Location } from './Location';

export class CustomerLocation extends Model {
    static backendResourceName = 'customer_location';

    @observable name = null;
    @observable type = null;
    @observable id = null;
    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            customer: Customer,
            location: Location,
        };
    }
}

export class CustomerLocationStore extends Store {
    Model = CustomerLocation;
    static backendResourceName = 'customer_location';
}
