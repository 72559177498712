import { BlueLink } from 'component/Compact/BlueLink';
import { TargetSelect } from 'component/Target';
import { DATETIME_FORMAT_PRETTY } from 'helpers';
import { t } from 'i18n';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { VirtualizedMessageStore } from 'store/Message';
import styled, { keyframes } from 'styled-components';
import { TruckStore } from 'store/Truck';
import { subscribe } from 'store/Base';
import VirtualizedList from 'component/VirtualizedList';
import { PageLoader } from '@code-yellow/spider';

const CustomToolbar = styled.div`
    height: 46px;
    padding: 8px 16px 8px 16px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: right;
    align-items: center;
`

const FormContainer = styled.div`
    padding: 16px 24px;
    .dropdown {
        width: 100%;
    }
`

const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 24px 0px 24px;
`

// Define the gradient animation using keyframes
const gradientShift = keyframes`
  0% {background-position: 0% 50%;}
  50% {background-position: 100% 50%;}
  100% {background-position: 0% 50%;}
`;

const StyledMessage = styled.div`
    padding: 12px 24px;
    
      ${({ loading }) => loading && `
        height: 80px;
        background: linear-gradient(-25deg, var(--gray-50), var(--gray-300), var(--white));
        background-size: 200% 200%;
        animation: ${gradientShift} 5s ease infinite;
    `};

    ${({ read }) => read &&
        'background-color: var(--yellow-50)};'
    };

    :hover {
        background-color: var(--blue-50);
    }
`

const StyledMessageTitle = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 4px;
`

const StyledMessageDescription = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 4px;
    cursor: pointer;

    display: -webkit-box;

    ${({ expand }) => !expand &&
        '-webkit-line-clamp: 3;'
    };

    -webkit-box-orient: vertical;
    overflow: hidden;
`

const StyledMessageCreated = styled.div`
    display:flex;
    justify-content: space-between;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: var(--gray-500);
    margin-bottom: 8px;
`

const ContentTitle = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
`

const MessagesContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

const MessagesList = styled.div`
    height: 100%;
    overflow-y: auto;
`

@observer
export default class SidebarContentMessages extends Component {
    static propTypes = {
        hideSidebarContent: PropTypes.func.isRequired,
    };

    @observable messageStore = new VirtualizedMessageStore({
        relations: ['truck'],
        params: {
            '.source': 'driver',
            '.read': 'false',
            'order_by': '-expected_send_datetime'
        },

    });

    @observable expandedMessages = [];
    @observable unreadMessages = null;

    _subscriptions = [];

    trucks = new TruckStore();

    async componentDidMount() {
        this.messageStore.fetch();
        this.subscribe();
    }

    componentWillUnmount() {
        this.unsubscribeAll();
    }

    subscribe() {
        this._subscriptions.push(subscribe({
            target: 'message-unread-count',
        }, action((msg) => {
            this.unreadMessages = msg.data;
            console.log(msg.data)
        })));
    }

    unsubscribeAll() {
        this._subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        })
    }

    setTrucksFilter(values) {
        this.messageStore.params['.truck.id:in'] = values?.toString();
        this.messageStore.fetch();
    }

    hide() {
        const { hideSidebarContent } = this.props;
        hideSidebarContent()
    }

    onMarkAsReadClick(e, message) {
        e.preventDefault();
        e.stopPropagation();
        message.markReadManual().then(() => {
            this.messageStore.remove(message);
        })
    }

    onRemoveClick(e, message) {
        e.preventDefault();
        e.stopPropagation();
        this.messageStore.remove(message);
    }

    onRefresh(e) {
        this.messageStore.fetch();
    }

    isRead(messageId) {
        if (this.unreadMessages?.ids == null) {
            return false;
        }

        return !this.unreadMessages.ids.includes(messageId)
    }

    newMessagesAvailable() {
        return this.unreadMessages?.ids?.length > this.messageStore.models?.length
    }

    emptyRowRenderer = (index) => {
        return (
            <StyledMessage key={index} data-test-sidebar-message loading />
        )
    }

    rowRenderer = observer((config) => {
        const { index, data } = config;

        if (data?.id == null) {
            return this.emptyRowRenderer(index);
        }

        const message = data;
        return (
            <StyledMessage key={index}
                read={this.isRead(message.id)}
                data-test-sidebar-message >
                <StyledMessageTitle>
                    {message.truck?.identificatorLong}
                </StyledMessageTitle>
                <StyledMessageDescription expand={message.expand}
                    onClick={() => { message.expand = !message.expand }}>
                    {message.text}
                </StyledMessageDescription>
                <StyledMessageCreated>
                    {message.expectedSendDatetime?.toFormat(DATETIME_FORMAT_PRETTY)}
                    {this.isRead(message.id) ?
                        <span>
                            <BlueLink
                                to=''
                                onClick={(e) => this.onRemoveClick(e, message)}
                                data-test-remove-message>
                                <Icon name='remove' />
                                {t('message.sidebar.clean')}
                            </BlueLink>
                        </span>
                        :
                        <span>
                            <BlueLink
                                to=''
                                onClick={(e) => this.onMarkAsReadClick(e, message)}
                                data-test-mark-as-read>
                                <Icon name='bookmark outline' />
                                {t('message.sidebar.markAsRead')}
                            </BlueLink>
                        </span>
                    }
                </StyledMessageCreated>
            </StyledMessage>
        )
    })

    render() {
        return (
            <MessagesContainer>
                {/* header */}
                <div>
                    {/* top */}
                    <TopSection>
                        <ContentTitle>
                            {t('message.sidebar.title')} ({this.unreadMessages?.unread ?? this.messageStore.models.length})
                        </ContentTitle>
                        <BlueLink to='/account/message/overview'
                            onClick={() => this.hide()} data-test-open-overview>
                            {t('message.sidebar.openOverview')}
                        </BlueLink>
                    </TopSection>
                    {/* form */}
                    <FormContainer>
                        <TargetSelect multiple remote search clearable
                            target={this.messageStore}
                            name={'.truck.id:in'}
                            store={this.trucks}
                            toOption={trucks => ({
                                value: trucks.id,
                                text: trucks.identificatorLong,
                            })}
                            afterChange={(values) => this.setTrucksFilter(values)}
                        />
                    </FormContainer>
                    {/* buttons */}
                    {this.newMessagesAvailable() &&
                        <CustomToolbar>
                            <Button icon fluid basic
                                labelPosition='left'
                                onClick={() => this.onRefresh()}
                                data-test-new-messages>
                                <Icon name='angle double down' />
                                {t('message.sidebar.newMessages')}
                            </Button>
                        </CustomToolbar>
                    }
                </div>
                {/* list */}
                <MessagesList data-test-messages-list>
                    {this.messageStore.isLoading && <PageLoader show/>}
                    <VirtualizedList store={this.messageStore} ElementTemplate={this.rowRenderer} />
                </MessagesList>
            </MessagesContainer>
        )
    }
}